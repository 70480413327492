import { CustomResolvers } from '../../../types';

const getLocalResolversKeysFromCustomResolvers = (
  customResolvers: CustomResolvers
): string[] => {
  const localResolversKeys = [] as string[];
  if (!customResolvers) {
    return localResolversKeys;
  }
  Object.keys(customResolvers).forEach((operation) => {
    Object.keys(customResolvers[operation]).forEach((key: string) => {
      if (!key.startsWith('local'))
        throw new Error(
          `Invalid local resolver key "${key}". It should start with "local" prefix.`
        );
      localResolversKeys.push(key);
    });
  });
  return localResolversKeys;
};

export default getLocalResolversKeysFromCustomResolvers;
