import ApolloClientGraphQLService from './services/GraphQLService/ApolloClientGraphQLService';
import { GraphQLService } from './services/GraphQLService/GraphQLService';
import { Platform, Stack } from './enums';
import createApolloClient from './infra/apollo/apolloClient';
import { gql, DocumentNode } from '@apollo/client/core';
import { CreateGraphQLClientOptions, CustomResolvers } from './types';
import AbstractLocalDevicesResolver from './resolvers/devices/shared/AbstractLocalDevicesResolver';
import { DeviceResolver } from './resolvers/devices/types';
import { DeviceResolverParamsType } from './resolvers/devices/shared/types';

const createGraphQLClient = ({
  authTokenCallback,
  platform,
  stack,
  mock,
  isShellPortal,
  serverUrl,
  additionalLocalDevicesResolvers,
  customResolvers
}: CreateGraphQLClientOptions): GraphQLService => {
  const apolloClient = createApolloClient({
    authTokenCallback,
    platform,
    stack,
    mock,
    isShellPortal,
    serverUrl,
    additionalLocalDevicesResolvers,
    customResolvers
  });
  const client = new ApolloClientGraphQLService(apolloClient);

  return client;
};

export {
  createGraphQLClient,
  gql,
  Platform,
  Stack,
  AbstractLocalDevicesResolver
};

export type {
  CreateGraphQLClientOptions,
  GraphQLService,
  DocumentNode,
  DeviceResolver,
  DeviceResolverParamsType,
  CustomResolvers
};
